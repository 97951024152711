import { b as b$1 } from './chunk-Y2N4YO7I.js';
import { b } from './chunk-KJESQRLQ.js';
import { a } from './chunk-LO7SB655.js';
import { h, k, i, j } from './chunk-KKD6NYT4.js';
import { c } from './chunk-ZFZIJ4A5.js';

var l=class extends k{constructor(){super(...arguments);this.embedController=new b(this,0);}connectedCallback(){super.connectedCallback(),this.embedController.token=this.token;}requestUpdate(e,r){super.requestUpdate(e,r),e==="embed"&&(this.embedController.token=this.token);}get _slottedChildren(){return this.shadowRoot?.querySelector("slot")?.assignedElements({flatten:!0})||[]}get _stylesheets(){if(document){let e=document.querySelectorAll('style, link[rel="stylesheet"]');return i`${Array.from(e).map(r=>r.cloneNode(!0))}`}else return null}updated(){this.shadowRoot&&b$1(this.shadowRoot);}render(){return i`
      ${this.embedController.render({pending:this.renderPending,error:e=>i`<p>${e instanceof Error?e.message:j}</p>`,complete:e=>{if(this._collection=e,!e)return this.renderPending();let r=this._slottedChildren.map(t=>{if(t.getAttribute("name")=="list-title")return t.textContent=this._collection.name,i`${t}`;if(t.nodeName==="TEMPLATE"||t.getAttribute("name")=="mave-list-item"){let g=this._collection.embeds.map(s=>{let n;t.nodeName==="TEMPLATE"?n=t.content.cloneNode(!0):(n=t.cloneNode(!0),t.remove());let i$1=n.querySelector('[slot="item-title"]');i$1&&(i$1.textContent=s.name,i$1.removeAttribute("slot"));let m=n.querySelector("mave-clip");m&&(m.setAttribute("embed",s.id),m.removeAttribute("slot"));let c=n.querySelector("mave-img");return c&&(c.setAttribute("embed",s.id),c.removeAttribute("slot")),i`${n}`});return i`${g}`}}).filter(t=>t);return i`${this._stylesheets} ${r} `}})}
    `}renderPending(){return i`<slot></slot>`}};l.styles=h`
    :host {
      display: block;
    }
  `,c([a()],l.prototype,"token",2);window&&window.customElements&&(window.customElements.get("mave-list")||window.customElements.define("mave-list",l));

export { l as a };
