import { a } from './chunk-LO7SB655.js';
import { h, k, i } from './chunk-KKD6NYT4.js';
import { c } from './chunk-ZFZIJ4A5.js';

var e=class extends k{get spaceId(){return this.embed.substring(0,5)}get embedId(){return this.embed.substring(5,this.embed.length)}get poster(){return `https://space-${this.spaceId}.video-dns.com/${this.embedId}/poster.webp`}render(){return i`<img src=${this.poster} />`}};e.styles=h`
    :host {
      display: block;
    }

    img {
      width: 100%;
      max-height: 100vh;
    }
  `,c([a()],e.prototype,"embed",2);window&&window.customElements&&(window.customElements.get("mave-img")||window.customElements.define("mave-img",e));

export { e as a };
