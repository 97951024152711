import { b as b$1, a as a$1, c as c$2 } from './chunk-4W3DKTKO.js';
import { a as a$2 } from './chunk-MK4VIJT3.js';
import { b as b$2 } from './chunk-KJESQRLQ.js';
import { a } from './chunk-LO7SB655.js';
import { h as h$1, k, i as i$1, j } from './chunk-KKD6NYT4.js';
import { b, c as c$1, e, f as f$1 } from './chunk-ZFZIJ4A5.js';

var f=b(b$1(),1);var d,h,p,q,c,I,u,x,i=class extends k{constructor(){super(...arguments);e(this,d);e(this,p);e(this,c);e(this,u);this.autoplay="lazy";this.quality="fhd";this._queue=[];this._intersectionObserver=new a$1(this,{callback:f$1(this,c,I).bind(this)});this.embedController=new b$2(this);}get embed(){return this._embedId}set embed(e){this._embedId!=e&&(this._embedId=e,this.requestUpdate("embed"),this.embedController.embed=this.embed);}get poster(){return this.embedController.embedFile("poster.webp")}set poster(e){if(e){let t=this._poster;this._poster=e,this.requestUpdate("poster",t);}}get token(){return this._token}set token(e){this._token!=e&&(this._token=e,this.requestUpdate("token"),this.embedController.token=this._token);}get deterministic_source(){return this.embedController.embedFile("h264_fhd.mp4")}disconnectedCallback(){super.disconnectedCallback(),this._metrics&&this._metrics.demonitor();}set muted(e){this._videoElement&&(this._videoElement.muted=e);}get muted(){return this._videoElement?.muted||!1}get paused(){return this._videoElement?.paused||!0}play(){this._videoElement?f$1(this,d,h).call(this):this._queue.push(()=>f$1(this,d,h).call(this));}pause(){this._videoElement?this._videoElement.pause():this._queue.push(()=>this._videoElement?.pause());}get highestMP4Rendition(){let e=this._embed.video.renditions.filter(s=>s.container=="mp4"),t=["sd","hd","fhd","qhd","uhd"];return e.filter(s=>{let a=t.indexOf(this.quality);return t.indexOf(s.size)<=a}).reduce((s,a)=>t.indexOf(a.size)>t.indexOf(s.size)?a:s)}render(){return i$1`
      ${this.embedController.render({pending:this.renderPending,error:e=>i$1`<p>${e instanceof Error?e.message:j}</p>`,complete:e=>(this._embed=e,e?i$1`
            <video
              @click=${f$1(this,u,x)}
              preload="metadata"
              muted
              playsinline
              ${c$2(f$1(this,p,q))}
              ?autoplay=${this.autoplay==="always"}
              ?loop=${this.loop||!0}
            >
            <source src=${this.deterministic_source} type="video/mp4" />
          </video>
          `:this.renderPending())})}
    `}renderPending(){return i$1`<video muted poster=${this.poster} src="" preload="none" loop></video>`}};d=new WeakSet,h=function(){this._metrics.monitor(),this._videoElement?.play();},p=new WeakSet,q=function(e){if(this._videoElement||(this._videoElement=e),this._intersectionObserver.observe(this._videoElement),this._videoElement&&this._embed&&!this._metrics){a$2.forEach(_=>{this._videoElement?.addEventListener(_,s=>{this.dispatchEvent(new CustomEvent(_,{detail:s,bubbles:!0,composed:!0}));});});let t={component:"clip",video_id:this._embed.video.id,space_id:this._embed.space_id};f.Metrics.config={socketPath:"wss://metrics.video-dns.com/socket",apiKey:this._embed.metrics_key},this._metrics=new f.Metrics(this._videoElement,this.embed,t);}this._queue.length&&(this._queue.forEach(t=>t()),this._queue=[]);},c=new WeakSet,I=function(e){for(let{isIntersecting:t}of e){if(!t||this.autoplay==="always")return;this.autoplay==="lazy"?this._videoElement?.paused&&f$1(this,d,h).call(this):this._videoElement?.paused||this._videoElement?.pause();}},u=new WeakSet,x=function(){this.autoplay==="off"&&(this._videoElement?.paused?f$1(this,d,h).call(this):this._videoElement?.pause());},i.styles=h$1`
    :host {
      display: block;
      position: relative;
    }

    video {
      all: initial;
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  `,c$1([a()],i.prototype,"embed",1),c$1([a()],i.prototype,"autoplay",2),c$1([a()],i.prototype,"loop",2),c$1([a()],i.prototype,"quality",2),c$1([a()],i.prototype,"poster",1),c$1([a()],i.prototype,"token",1);window&&window.customElements&&(window.customElements.get("mave-clip")||window.customElements.define("mave-clip",i));

export { i as a };
