import { a, b as b$1 } from './chunk-KKD6NYT4.js';

var f={attribute:!0,type:String,converter:a,reflect:!1,hasChanged:b$1},y=(e=f,r,t)=>{let{kind:a,metadata:i}=t,n=globalThis.litPropertyMetadata.get(i);if(n===void 0&&globalThis.litPropertyMetadata.set(i,n=new Map),n.set(t.name,e),a==="accessor"){let{name:o}=t;return {set(c){let s=r.get.call(this);r.set.call(this,c),this.requestUpdate(o,s,e);},init(c){return c!==void 0&&this.C(o,void 0,e),c}}}if(a==="setter"){let{name:o}=t;return function(c){let s=this[o];r.call(this,c),this.requestUpdate(o,s,e);}}throw Error("Unsupported decorator location: "+a)};function m(e){return (r,t)=>typeof t=="object"?y(e,r,t):((a,i,n)=>{let o=i.hasOwnProperty(n);return i.constructor.createProperty(n,o?{...a,wrapped:!0}:a),o?Object.getOwnPropertyDescriptor(i,n):void 0})(e,r,t)}function b(e){return m({...e,state:!0,attribute:!1})}var u=(e,r,t)=>(t.configurable=!0,t.enumerable=!0,Reflect.decorate&&typeof r!="object"&&Object.defineProperty(e,r,t),t);function P(e,r){return (t,a,i)=>{let n=o=>o.renderRoot?.querySelector(e)??null;if(r){let{get:o,set:c}=typeof a=="object"?t:i??(()=>{let s=Symbol();return {get(){return this[s]},set(d){this[s]=d;}}})();return u(t,a,{get(){if(r){let s=o.call(this);return s===void 0&&(s=n(this),c.call(this,s)),s}return n(this)}})}return u(t,a,{get(){return n(this)}})}}/*! Bundled license information:

@lit/reactive-element/decorators/property.js:
  (**
   * @license
   * Copyright 2017 Google LLC
   * SPDX-License-Identifier: BSD-3-Clause
   *)

@lit/reactive-element/decorators/state.js:
  (**
   * @license
   * Copyright 2017 Google LLC
   * SPDX-License-Identifier: BSD-3-Clause
   *)

@lit/reactive-element/decorators/base.js:
  (**
   * @license
   * Copyright 2017 Google LLC
   * SPDX-License-Identifier: BSD-3-Clause
   *)

@lit/reactive-element/decorators/query.js:
  (**
   * @license
   * Copyright 2017 Google LLC
   * SPDX-License-Identifier: BSD-3-Clause
   *)

@lit/reactive-element/decorators/custom-element.js:
  (**
   * @license
   * Copyright 2017 Google LLC
   * SPDX-License-Identifier: BSD-3-Clause
   *)

@lit/reactive-element/decorators/event-options.js:
  (**
   * @license
   * Copyright 2017 Google LLC
   * SPDX-License-Identifier: BSD-3-Clause
   *)

@lit/reactive-element/decorators/query-all.js:
  (**
   * @license
   * Copyright 2017 Google LLC
   * SPDX-License-Identifier: BSD-3-Clause
   *)

@lit/reactive-element/decorators/query-async.js:
  (**
   * @license
   * Copyright 2017 Google LLC
   * SPDX-License-Identifier: BSD-3-Clause
   *)

@lit/reactive-element/decorators/query-assigned-elements.js:
  (**
   * @license
   * Copyright 2021 Google LLC
   * SPDX-License-Identifier: BSD-3-Clause
   *)

@lit/reactive-element/decorators/query-assigned-nodes.js:
  (**
   * @license
   * Copyright 2017 Google LLC
   * SPDX-License-Identifier: BSD-3-Clause
   *)
*/

export { m as a, b, P as c };
