import { a } from './chunk-7XMZVGV4.js';
import { c as c$2 } from './chunk-LO7SB655.js';
import { h, k, i } from './chunk-KKD6NYT4.js';
import { c as c$1 } from './chunk-ZFZIJ4A5.js';

var o=class extends k{open(e){this._player=e;let t=n=>{this._content.style.backgroundImage=`url(${e.poster})`,this._content.appendChild(e),setTimeout(()=>{this._dialog.showModal();},25),this._dialog.addEventListener("cancel",r=>{r.preventDefault(),this.close();},{once:!0}),n(this);};return new Promise(n=>{if(this._dialog)t(n);else {let r=setInterval(()=>{this._dialog&&(t(n),clearInterval(r));},100);return}})}possibleClose(e){e.target!=this._player&&this.close();}close(){this._dialog.close(),this._backdrop.addEventListener("transitionend",()=>{this._content.innerHTML="",this.dispatchEvent(new Event("closed",{bubbles:!0}));},{once:!0});}render(){return i`
      <dialog>
        <div class="backdrop"></div>
        <div class="content" @click=${this.possibleClose}></div>
        <div class="button-close" @click=${this.close}>
          <svg
            width="24px"
            height="24px"
            stroke-width="1"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            color="currentColor"
          >
            <path
              d="M6 12h6m6 0h-6m0 0V6m0 6v6"
              stroke="currentColor"
              stroke-width="1"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
          </svg>
        </div>
      </dialog>
    `}};o.styles=h`
    :host {
      all: initial;
    }

    dialog {
      display: block;
      margin: 0;
      padding: 0;
      border: 0;
      pointer-events: none;
      background: transparent;
    }

    dialog .content {
      position: fixed;
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
      opacity: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: scale(0.96);
      transition-property: transform, opacity;
      transition-duration: 200ms;
      transition-timing-function: ease-out;
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
    }

    .backdrop {
      position: fixed;
      background: black;
      opacity: 0;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      transition-property: opacity;
      transition-duration: 200ms;
      transition-timing-function: ease-out;
    }

    dialog[open] .backdrop {
      opacity: 1;
    }

    dialog::backdrop {
      background: none;
    }

    dialog[open] {
      pointer-events: auto;
    }

    dialog[open] .content {
      opacity: 1;
      transform: scale(1);
    }

    dialog[open] .button-close {
      display: block;
    }

    .button-close {
      display: none;
      position: fixed;
      top: 0.8rem;
      right: 0.6rem;
      width: 2.75rem;
      height: 2.75rem;
      transition-property: transform;
      transition-duration: 100ms;
      transition-timing-function: ease-out;
    }

    .button-close:hover {
      cursor: pointer;
      transform: scale(1.3);
    }

    .button-close svg {
      color: white;
      width: 2.75rem;
      height: 2.75rem;
      transform: rotate(45deg);
    }
  `,c$1([c$2("dialog")],o.prototype,"_dialog",2),c$1([c$2(".content")],o.prototype,"_content",2),c$1([c$2(".backdrop")],o.prototype,"_backdrop",2);window&&window.customElements&&(window.customElements.get("mave-pop")||window.customElements.define("mave-pop",o));var c=new o,v=l=>{!document||!document.body||l.querySelectorAll("[x-mave-pop]").forEach(i=>{document.querySelector("mave-pop")||document.body.appendChild(c);let e=i.getAttribute("x-mave-pop")||i.getAttribute("embed");if(!e)return;i.style.cursor="pointer";let t=new a;t.embed=e;let n=new Image;n.src=t.poster,i.addEventListener("click",r=>{r.preventDefault();let g=document.querySelectorAll("mave-player");Array.from(g).find(d=>d.popped&&d.embed===e)||(c.open(t).then(()=>{t.play();}),c.addEventListener("closed",()=>{t.pause();}));});});};document&&v(document);

export { o as a, v as b };
